@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

* {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR',
    'Malgun Gothic', sans-serif;
}
html {
  font-size: -webkit-calc(6px + 0.5vw);
  font-size: calc(6px + 0.5vw);
}
html,
body {
  height: 100%;
  min-height: 100%;
  color: #fff;
  background: #000;
}
#root {
  height: 100%;
  min-height: 100%;
}
.speaner {
  z-index: 9999;
}
.translate-center {
  transform: translate(-50%, -50%);
}
.bg-card-gray {
  background: #202023;
}
.bg-card-deep-gray {
  background: #201a32;
}
.btn-border-line {
  border: 1px solid #a24159;
}
.top--50per {
  transform: translateY(-50%);
}
.Toastify {
  z-index: 9999;
}
@layer components {
  input {
    @apply w-full px-5 py-3 border-2;
  }
  .btn {
    @apply flex items-center justify-center w-full text-3xl font-bold text-white bg-purple-600 rounded-full h-28 md:h-16 md:text-xl btn-gradient;
  }
  .btn-line {
    @apply flex items-center justify-center w-full text-3xl font-bold text-white rounded-full h-28 md:h-16 md:text-xl btn-border-line;
  }
  .text-box {
    @apply px-10 py-5 text-2xl border border-gray-400 bg-card-gray md:py-4 lg:py-2 md:px-5;
  }

  .btn-text {
    @apply flex items-center justify-center w-full text-3xl font-bold text-pink-800 rounded-full h-14;
  }
  @variants responsive {
    .min-w-640 {
      min-width: 640px;
    }
    .max-w-420 {
      max-width: 420px;
    }
    .max-w-640 {
      max-width: 640px;
    }
    .max-w-1024 {
      max-width: 1024px;
    }
    .max-w-1240 {
      max-width: 1240px;
    }
    .max-w-1440 {
      max-width: 1440px;
    }
  }
  @variants hover {
    .hover-gradient:hover {
      color: #fff;
      background: linear-gradient(to right, #5a356a, #b34354);
      border-color: #fff;
    }
  }
}
button:disabled,
.btn:disabled {
  color: #6b7280;
  background: #e5e7eb !important;
  cursor: not-allowed;
}
.min-w-320 {
  min-width: 320px;
}

.data-number-list li:before {
  color: #fff;
  width: 28px;
  content: attr(data-index);
  display: inline-block;
}
.loa-gradient {
  background: linear-gradient(144.14deg, #603669 -1.09%, #a84257 104.06%);
}
.btn-gradient {
  background: linear-gradient(to right, #5a356a, #b34354);
}
.loader {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.loader span {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-top-color: #5a356a;
  border-bottom-color: #5a356a;
  -webkit-animation: single4 2.5s infinite ease-in-out;
  animation: single4 2.5s infinite ease-in-out;
}
@keyframes single4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(540deg);
    transform: rotate(540deg);
  }
}
